import React from 'react'

const TextHero = () => {
    
    
    let lead = "Mathison was built to serve as an artifically intelligent complex event processing platform."
    let theh2 = "Artifical Intelligence is a platform, not a feature"
    
        return (
            <div>
                <div className="hero">
                    <h1>{lead}</h1>
                    <h2>{theh2}</h2>
                    <div className="herocopy">
                        Most systems can tell you what happened in the past.  Mathison can tell you what is going to happen next. The world
                        is complex and ever changing.  Mathison helps your business understand how to navigate when all of the old formulas
                        are out the window. 
                    </div>
                    <span><a href="/products">Services</a> <a href="/blog">Use Cases</a></span>
                </div>
            </div>
            
        )
}    

export default TextHero