import React from 'react'

const FeatureCard = () => {
    
    
        return (
            <div>
                <div className="columns">
                    <div className="roundedfeature column is-4">
                        <div className="card">
                            <div className="card-image">
                                <div class="image is-4by4">
                                    <img src="/img/algorithm.png" alt="Supply Chain Graph"></img>
                                </div>
                            </div>
                            <div className="card-content">
                                <div className="content">
                                 <h4>Supply Chain Management Graph</h4>   
                                <p>Supply Chain security starts here.  Predict disruptions and manage risk when the old formulas don't exist anymore.</p>  
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="roundedfeature column is-4">
                        <div className="card">
                            <div className="card-image">
                                <div class="image is-4by4">
                                    <img src="/img/management.png" alt="Customer Experience Engineering"></img>
                                </div>
                            </div>
                            <div className="card-content">
                                <div className="content">
                                 <h4>Customer Experience Engineering</h4>   
                                <p>Customers expect a new experience.  Address privacy concerns and rebuild your customer demand chain.</p>  
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="roundedfeature column is-4">
                        <div className="card">
                            <div className="card-image">
                                <div class="image is-4by4">
                                    <img src="/img/HUD.png" alt="Service Chain"></img>
                                </div>
                            </div>
                            <div className="card-content">
                                <div className="content">
                                 <h4>The Emergent Service Chain</h4>   
                                <p>Take advantage of gig-economy and just in time services, resiliently, to manage spikes and drops in demand.</p>  
                                </div>
                            </div>
                          
                        </div>
                    </div>

                    
                </div>

                <div className="columns">
                    <div className="roundedfeature column is-4">
                        <div className="card">
                            <div className="card-image">
                                <div class="image is-4by4">
                                    <img src="/img/automated.png" alt="Manufacturing IoT"></img>
                                </div>
                            </div>
                            <div className="card-content">
                                <div className="content">
                                 <h4>Manufacturing IoT</h4>   
                                <p>Reshoring initiaves and supply chain diversification require real time data flows to enable efficient automation.</p>  
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="roundedfeature column is-4">
                        <div className="card">
                            <div className="card-image">
                                <div class="image is-4by4">
                                    <img src="/img/datascience.png" alt="Graph of Society"></img>
                                </div>
                            </div>
                            <div className="card-content">
                                <div className="content">
                                 <h4>Society Graph</h4>   
                                <p>All politics, and data, are local.  Make market place risk assessments that affect your overall enterprise in real time.</p>  
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="roundedfeature column is-4">
                        <div className="card">
                            <div className="card-image">
                                <div class="image is-4by4">
                                    <img src="/img/datavisualization.png" alt="The MetaChain"></img>
                                </div>
                            </div>
                            <div className="card-content">
                                <div className="content">
                                 <h4>The MetaChain</h4>   
                                <p>Tame the Franken-SaaS and make 360 degree sense of your data without replacing software.</p>  
                                </div>
                            </div>
                          
                        </div>
                    </div>

                    
                </div>

                 
            </div>
            
        )
}    

export default FeatureCard