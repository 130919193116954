import React from 'react'

const BoxRowRight = () => {
    
    let copyh2 = "Human In The Loop AI"
    
        return (
            <div className="columns boxrow">
                <div className="column is-half is-5by4">
                <img src="/img/cep.svg"></img>
                </div>
                <div className="column is-half">
                    <h2>{copyh2}</h2>
                    <div className="copyh3">
                        H.I.T.L. is a hybrid form of human intelligence and machine intelligence.  It rapidly
                        increases the velocity of model creation and implementation.  The reality is, all productive
                        business Artificial Intelligence is about looping the humans in.  Humans can label data very
                        effectively.  Providing this kind of feedback in real time with our novel congnitive architecture
                        enables new approaches to A.I. as well as augments the usage of tried and true approaches to machine
                        learning.  This super charges our implementations.

                    </div>
                    <div className="smallcopy">
                        By using your people and augmenting them with our Data Service Reps, we achieve optimal operationalization
                        of novel artificial intelligence models.  At the same time we are re-optimizing, re-training and operationalizing
                        new models.  
                    </div>
                </div>
            </div>
            
        )
}    

export default BoxRowRight