import React from 'react'
import PropTypes from 'prop-types'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

const FeatureGrid = ({ gridItems }) => (
  <div className="columns is-multiline">
    {gridItems.map(item => (
      <div key={item.text} className="column is-6">
        <div className="roundedfeature column is-6">
                        <div className="card">
                            <div className="card-image">
                                <div class="image is-4by4">
                                    <img src="/img/HUD.png" alt="Placeholder image"></img>
                                </div>
                            </div>
                            <div className="card-content">
                                <div className="content">
                                 <h4>{item.text}</h4>   
                                
                                </div>
                            </div>
                          
                        </div>
                    </div>
      </div>
    ))}
  </div>
)

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
}

export default FeatureGrid
